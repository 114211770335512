
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import { AddSiteStation, SiteTypes } from "@/store/modules/site/site.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ZoneSelect from "../zone/ZoneSelect.vue";

const siteX = namespace("Site");

@Component({
  components: { DatePicker, ZoneSelect },
})
export default class AddSite extends Mixins(ValidationMixin) {
  @siteX.State(SiteTypes.ADD_SITE_STATE)
  public addStation!: DefaultState;

  @siteX.Mutation(SiteTypes.SET_ADD_SITE_DIALOG)
  public setAddStation!: (addStation: boolean) => void;

  @siteX.Action(SiteTypes.ADD_SITE)
  public saveStation!: (farmer: AddSiteStation) => Promise<void>;

  public addStationStep = 1;

  public isValid = false;

  public site: AddSiteStation = {
    name: "",
    description: "",
    region: "",
    zone: "",
    active: true,
  };

  public regions = ["NORTHERN", "SOUTHERN", "CENTRAL", "EASTERN", "WESTERN"];

  @Ref("form") public formRef!: Validator;

  closeAddStationDialog(): void {
    this.setAddStation(false);
  }

  async saveStationToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveStation(this.site);
    }
  }

  // mounted(): void {}
}
