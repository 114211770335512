
import { SiteZone, ZoneTypes } from "@/store/modules/zone/zone.types";
import { Pagination } from "@/types/types";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const zoneX = namespace(ZoneTypes.MODULE);

@Component({})
export default class ZoneSelect extends Vue {
  @Prop() public label?: string;
  @Prop() public filled?: boolean;
  @Prop() public outlined?: boolean;
  @Prop() public dense?: boolean;
  @Prop() public hideDetails?: boolean;
  @Prop() public rules?: unknown[];
  @Prop() value?: number;

  @zoneX.Action(ZoneTypes.LOAD_ZONES)
  public getZones!: (pagination: Pagination) => void;

  @zoneX.State(ZoneTypes.ZONES)
  public zones!: SiteZone[];

  @zoneX.State(ZoneTypes.LOADING_ZONES_STATE)
  public loadingZones!: boolean;

  @Emit("input")
  save(id: string): string {
    return id;
  }

  mounted(): void {
    this.getZones({ page: 1, limit: 10 });
  }
}
